import styled from 'styled-components'

interface IProps {
  expand: boolean
}

interface IStyleProps {
  questionBGColor?: string
  answerBGColor?: string
  answerLineColor?: string
}

export const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
export const AccordionQuestion = styled.div<IStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px;
  width: 100%;
  background-color: ${({ questionBGColor }) =>
    questionBGColor || 'var(--grey-100)'};
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
`

export const AccordionExpand = styled.div<IProps>`
  position: relative;
  transition: ${({ expand }) =>
    expand ? 'max-height 0.5s ease-in' : 'max-height 0.3s ease'};
  max-height: ${({ expand }) => (expand ? '1000px' : '0px;')};
  height: auto;
  overflow: hidden;
  width: 100%;
  margin-top: -24px;
`
export const AccordionAnswer = styled.div<IStyleProps>`
  height: auto;
  width: 100%;
  padding: 24px;
  background-color: ${({ answerBGColor }) =>
    answerBGColor || 'var(--grey-100)'};
  border-radius: 0px 0px 5px 5px;
`
export const IconArea = styled.div<IProps>`
  transition: ${({ expand }) =>
    expand ? 'transform 0.3s ease-in' : 'transform 0.3s ease-in'};
  height: 18px;
  width: 10px;
  transform: ${({ expand }) =>
    expand
      ? 'rotate(-180deg) translateX(-9px)'
      : 'rotate(0deg) translateX(0px)'};
`
export const Linha = styled.hr<IStyleProps>`
  position: absolute;
  top: 0px;
  left: 24px;
  width: 936px;
  height: 1px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: ${({ answerLineColor }) =>
    answerLineColor || 'rgba(0, 0, 0, 0.1)'};
  border-radius: 0px 0px 5px 5px;
`
