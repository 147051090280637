import React from 'react'
import { WhatsappIconSVG } from '../Svg/WhatsappIconSVG'
import {
  A,
  AreaTexto,
  Comercial,
  Container,
  Contato,
  Localização,
} from './styles'

const Footer = () => {
  return (
    <Container>
      <AreaTexto>
        <span>
          Somos especialistas em desenvolvimento de sistema para gerenciamento
          de empresas do setor automotivo.
        </span>
        <Contato>
          <span>(11) 3042-5369</span>
          <span>-</span>

          <Comercial>
            <WhatsappIconSVG width={16} height={16} color={'var(--white'} />
            <A
              href="https://wa.me/message/VZM7F3Z7F2LTK1"
              target="_blank"
              rel="noreferrer"
            >
              (11) 98232-3059
            </A>
          </Comercial>
          <span className="tracoLocalizacao">-</span>
          <Localização>São Paulo - SP</Localização>
        </Contato>
        <span>
          © 2022 CICOM INFORMÁTICA E COMUNICAÇÃO LTDA. Todos os direitos
          reservados.
        </span>
      </AreaTexto>
    </Container>
  )
}

export { Footer }
