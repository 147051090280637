import React, { useState, useEffect } from 'react'

import {
  AreaDuvidas,
  AreaSaibaMais,
  AreaTexto,
  Conteudo,
  LinhasDiagonais,
  LinhasVerticais,
  SaibaMaisTemp,
  Titulo,
} from './styles'
import { Pergunta } from './pergunta'
import { Resposta } from './resposta'
import { Accordion } from '../Accordion'
import riscosVerticais from '../../assets/imgs/riscosVerticalDuvidas.png'
import riscosDiagonais from '../../assets/imgs/riscosDiagonalDuvidas.png'
import axios from 'axios'
import { Loader } from '../Loader'
import { Link } from 'react-router-dom'
import { ReturnToTop } from '../ReturnToTop'

interface IArrayFAQ {
  cod_pergunta: number
  pergunta: string
  resposta: string
}

interface IDuvidasProps {
  titulo: string
  home?: boolean
}

function Perguntas({ titulo, home = false }: IDuvidasProps) {
  const [faq, setFaq] = useState<Array<IArrayFAQ>>([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    async function getFAQ() {
      await axios
        .get(
          `https://www.sistemacicom.com.br/solid/api_admcicom/question${
            home ? '/home' : ''
          }`,
        )
        .then((resp) => setFaq(resp.data))
      setLoaded(true)
    }
    getFAQ()
  }, [home])

  return (
    <Conteudo id="Questions">
      <LinhasVerticais src={riscosVerticais} />
      <LinhasDiagonais src={riscosDiagonais} />
      <AreaTexto>
        <Titulo>{titulo}</Titulo>
      </AreaTexto>
      {home ? (
        <></>
      ) : (
        <ReturnToTop
          top="576px"
          left="100%"
          responsiveHide={true}
          hideInPX={'790px'}
          textColor="var(--white)"
          marginBottom="-120px"
        />
      )}

      {loaded ? (
        <AreaDuvidas>
          {faq.map((obj: IArrayFAQ, index: number) => (
            <Accordion
              key={obj.cod_pergunta}
              question={<Pergunta pergunta={obj.pergunta} />}
              answer={<Resposta resposta={obj.resposta} />}
            />
          ))}
        </AreaDuvidas>
      ) : (
        <Loader color="var(--white)" />
      )}
      {home ? (
        <AreaSaibaMais>
          <Link to="/duvidas" className="link-botao">
            <SaibaMaisTemp>Ver mais perguntas frequentes</SaibaMaisTemp>
          </Link>
        </AreaSaibaMais>
      ) : (
        <></>
      )}
    </Conteudo>
  )
}

export { Perguntas }
